import React from "react";
import {Link} from "gatsby";
import PageWrapper from "../../components/PageWrapper";
import News from "../../sections/home1/News";
import SEO from '../../components/SEO';
import metaImg from '../../assets/image/metaimg/news.jpg';

// import Section from "../layout/Section";
 
const IndexPage = () => {
  return (
    <>
      <PageWrapper        
        themeConfig={{
          headerClassName: "site-header--menu-left",
          headerFluid: false,

          footerStyle: "digma",
        }}>
        <SEO
          title="Keep track of the Latest News On Interactive Videos"
          description="Latest news of Digma's Interactive Videos and other interesting informative topics"
          image={metaImg}
        />   
         <div className="container pt-20 pt-md-16 pt-lg-20 ">
          <nav className="breadcrumb" aria-label="Breadcrumb">
            <ol className="breadcrumb__list">
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/">Home</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/info/">Info</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li>              
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/info/news">News</Link>
              </li>
              </ol>
           </nav>
        </div>   
        <News className="pt-4 pt-md-6 pt-lg-10 pb-13 pb-md-17 pb-lg-26" />
      </PageWrapper>
    </>
  );
};
export default IndexPage;
