import React from "react";
import BlogRoll from '../../components/BlogRoll'

const Blog = ({ className, ...rest }) => {
  return (
    <>
      <div className={className} {...rest}>
        <div className="container">
          {/* <!-- Section Title --> */}
          <div className="mb-10 mb-lg-17">
            <div className="row align-items-center justify-content-center justify-content-lg-start">
              <div className="col-xl-6 col-lg-6 col-xs-10">
                {/* <!-- Section Title --> */}
                <div
                  className="text-center text-lg-left"
                  data-aos="fade-right"
                  data-aos-duration="500"
                >
                  <h1 className="font-size-9 font-weight-medium pr-md-6 pr-lg-9 pr-xl-0 mb-0">
                    Latest News 
                  </h1>
                  <h2>on Interactive Videos</h2>
                  <h3>and other emerging technologies</h3>
                </div>
                {/* <!-- End Section Title --> */}
              </div>
            </div>
          </div>
          {/* <!-- End Section Title --> */}
          <div
            className="row justify-content-center"
            data-aos="zoom-in"
            data-aos-duration="1200"
          >
            <div className="col-lg-12 col-md-10">
              <BlogRoll />        
            </div>
           </div>
        </div>
      </div>
    </>
  );
};

export default Blog;
