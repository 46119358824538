import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql, StaticQuery } from 'gatsby';
import PreviewCompatibleImage from '../PreviewCompatibleImage';

class BlogRoll extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;

    return (
      <div className="columns flex wrap is-multiline">
        {posts &&
          posts.map(({ node: post }) => (
            <div className="col-lg-6">
              <Link
                className="title has-text-primary is-size-4 w-100"
                to={post.frontmatter.slug}
              >
                <div
                  className="is-parent column is-6 bg-white d-xs-flex align-items-center px-9 py-10 mb-9 shadow-2 gr-hover-1"
                  key={post.id}
                >
                  <article
                    className={`blog-list-item tile is-child box notification ${
                      post.frontmatter.featuredpost ? 'is-featured' : ''
                    }`}
                  >
                    <header className="d-xs-flex align-items-center">
                      {post.frontmatter.thumbnail ? (
                        <div className="featured-thumbnail">
                          <PreviewCompatibleImage
                            imageInfo={{
                              image: post.frontmatter.thumbnail,
                              alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                            }}
                          />
                        </div>
                      ) : null}
                      <div className="featured-copy">
                        <h4 className="font-size-8 font-weight-medium text-dark-cloud mb-5 line-height-34">
                          {post.frontmatter.title}
                        </h4>
                        <p className="post-meta">
                          <span className="text-bali-gray font-size-3 pr-9">
                            <i className="fa fa-clock mr-2"></i>
                            {post.frontmatter.date}
                          </span>
                        </p>
                      </div>
                    </header>
                  </article>
                </div>
              </Link>
            </div>
          ))}
      </div>
    );
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              frontmatter {
                title
                slug
                thumbnail {
                  childImageSharp {
                    fluid(maxWidth: 400, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
                date(formatString: "MMMM DD, YYYY")
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
);
